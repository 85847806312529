import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState, useMemo } from 'react';
import { Button, Select, Size, Space, Tooltip } from '@hse-design/react';
import { ComponentLinks, FigmaEmbed } from '../common';
import { Playground, Props } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tooltip"
    }}>{`Tooltip`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Tooltip } from '@hse-design/react'
`}</code></pre>
    <ComponentLinks name={'Tooltip'} figma="https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=941%3A0" storybook="/?path=/story/tooltip-tooltip--playground" vueStorybook="/?path=/story/tooltip-tooltip--playground" mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Tooltip — это всплывающая подсказка, не требующая действий.
Используется для объяснения элемента или функции пользовательского интерфейса.`}</p>
    <p>{`Не рекомендуется использовать Tooltip для длинного (более 10 слов) или сложного контента.
Для этих случаев используйте компонент Popover.`}</p>
    <p>{`Обычно всплывающие подсказки используются для того, чтобы:`}</p>
    <ul>
      <li parentName="ul">{`помочь пользователям понять значение или назначение иконок`}</li>
      <li parentName="ul">{`показать полную версию частично скрытого текста`}</li>
      <li parentName="ul">{`показать краткое описание кнопки / текста / операции / изображения`}</li>
    </ul>
    <p>{`Tooltip оборачивает элемент, к которому необходимо добавить подсказку.
Этот элемент надо передать как `}<inlineCode parentName="p">{`children`}</inlineCode>{`.
Нельзя передавать несколько элементов или `}<inlineCode parentName="p">{`React.Fragment`}</inlineCode>{` (если это все-таки нужно, передайте в `}<inlineCode parentName="p">{`children`}</inlineCode>{` функцию, см. примеры ниже).
Содержимое тултипа передается в проп `}<inlineCode parentName="p">{`content`}</inlineCode>{`.`}</p>
    <p>{`По умолчанию тултип — неконтролируемый.
Он автоматически появляется при наведении курсора или фокусе на элемент, переданный в `}<inlineCode parentName="p">{`children`}</inlineCode>{`.
Видимость Tooltip можно контролировать извне с помощью пропа `}<inlineCode parentName="p">{`visible`}</inlineCode>{`.
В таком случае автоматическое поведение отключается.`}</p>
    <p>{`Тултип может содержать кнопку закрытия.
Для её отображения передайте проп `}<inlineCode parentName="p">{`closable`}</inlineCode>{`.
При нажатии на кнопку вызывается колбек `}<inlineCode parentName="p">{`onClose`}</inlineCode>{`.`}</p>
    <p>{`У компонента есть 3 варианта отображения: `}<inlineCode parentName="p">{`brand`}</inlineCode>{`, `}<inlineCode parentName="p">{`black`}</inlineCode>{`, `}<inlineCode parentName="p">{`invert`}</inlineCode>{`. Передайте в свойство `}<inlineCode parentName="p">{`variant`}</inlineCode>{` нужный.
Выбирайте тот вариант, который лучше подходит с учетом цветов окружающих элементов.`}</p>
    <p>{`Tooltip можно позиционировать с помощью пропа `}<inlineCode parentName="p">{`placement`}</inlineCode>{` (см. примеры ниже).`}</p>
    <Playground __position={1} __code={'<Tooltip content={\'Hello there\'}>\n  <div style={{ display: \'inline-block\' }}>\n    <Button>Hover over me</Button>\n  </div>\n</Tooltip>'} __scope={{
      props,
      DefaultLayout,
      useState,
      useMemo,
      Button,
      Select,
      Size,
      Space,
      Tooltip,
      ComponentLinks,
      FigmaEmbed,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Tooltip content={'Hello there'} mdxType="Tooltip">
    <div style={{
          display: 'inline-block'
        }}>
      <Button mdxType="Button">Hover over me</Button>
    </div>
  </Tooltip>
    </Playground>
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node='?node-id=9786%253A204' mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "поведение"
    }}>{`Поведение`}</h2>
    <FigmaEmbed node='?node-id=9786%3A199' mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node='?node-id=9786%3A229' mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "children"
    }}>{`Children`}</h3>
    <p>{`Аналогично Popover, при использовании `}<inlineCode parentName="p">{`children`}</inlineCode>{` в Tooltip необходимо следить за тем,
чтобы передавался только один элемент, который может принять реф.`}</p>
    <p>{`Если элемент не принимает реф, то можно например обернуть его в `}<inlineCode parentName="p">{`div`}</inlineCode>{`.
Большинство компонентов ДС на текущий момент не принимают рефы.`}</p>
    <Playground __position={5} __code={'<Tooltip content={\'Hello there\'}>\n  <div style={{ display: \'inline-block\' }}>\n    <Button>Hover over me</Button>\n  </div>\n</Tooltip>'} __scope={{
      props,
      DefaultLayout,
      useState,
      useMemo,
      Button,
      Select,
      Size,
      Space,
      Tooltip,
      ComponentLinks,
      FigmaEmbed,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Tooltip content={'Hello there'} mdxType="Tooltip">
    <div style={{
          display: 'inline-block'
        }}>
      <Button mdxType="Button">Hover over me</Button>
    </div>
  </Tooltip>
    </Playground>
    <p>{`Если элемент принимает реф в каком-то специальном пропе, то имя этого пропа можно передать через `}<inlineCode parentName="p">{`refPropName`}</inlineCode>{`.
Например, компонент Button принимает реф в пропе `}<inlineCode parentName="p">{`innerRef`}</inlineCode>{`.`}</p>
    <Playground __position={6} __code={'<Tooltip content={\'Hello there\'} refPropName=\"innerRef\">\n  <Button>Hover over me</Button>\n</Tooltip>'} __scope={{
      props,
      DefaultLayout,
      useState,
      useMemo,
      Button,
      Select,
      Size,
      Space,
      Tooltip,
      ComponentLinks,
      FigmaEmbed,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Tooltip content={'Hello there'} refPropName="innerRef" mdxType="Tooltip">
    <Button mdxType="Button">Hover over me</Button>
  </Tooltip>
    </Playground>
    <p>{`В сложных случаях можно использовать рендер-проп (передать функцию в качестве `}<inlineCode parentName="p">{`children`}</inlineCode>{`).
Единственным аргументом туда передается объект с пропами:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`setRef`}</inlineCode>{` необходимо пробросить в нужный элемент`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onMouseEnter`}</inlineCode>{`/`}<inlineCode parentName="li">{`onMouseLeave`}</inlineCode>{` используются для отображения или скрытия тултипа при ховере`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onFocus`}</inlineCode>{`/`}<inlineCode parentName="li">{`onBlur`}</inlineCode>{` используются для отображения или скрытия тултипа при фокусе`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`show`}</inlineCode>{`/`}<inlineCode parentName="li">{`hide`}</inlineCode>{`/`}<inlineCode parentName="li">{`setVisible`}</inlineCode>{` можно вызвать в любой момент, чтобы показать или скрыть тултип`}</li>
    </ul>
    <Playground __position={7} __code={'<Tooltip content={\'Hello there\'}>\n  {({\n    setRef,\n    onMouseEnter,\n    onMouseLeave,\n    onFocus,\n    onBlur,\n    show,\n    hide,\n    setVisible,\n  }) => (\n    <Button\n      innerRef={setRef}\n      onMouseEnter={onMouseEnter}\n      onMouseLeave={onMouseLeave}\n      onFocus={onFocus}\n      onBlur={onBlur}\n    >\n      Hover over me\n    </Button>\n  )}\n</Tooltip>'} __scope={{
      props,
      DefaultLayout,
      useState,
      useMemo,
      Button,
      Select,
      Size,
      Space,
      Tooltip,
      ComponentLinks,
      FigmaEmbed,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Tooltip content={'Hello there'} mdxType="Tooltip">
    {({
          setRef,
          onMouseEnter,
          onMouseLeave,
          onFocus,
          onBlur,
          show,
          hide,
          setVisible
        }) => <Button innerRef={setRef} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onFocus={onFocus} onBlur={onBlur} mdxType="Button">
        Hover over me
      </Button>}
  </Tooltip>
    </Playground>
    <h3 {...{
      "id": "варианты-отображения"
    }}>{`Варианты отображения`}</h3>
    <p>{`У компонента есть 3 варианта отображения: `}<inlineCode parentName="p">{`brand`}</inlineCode>{`, `}<inlineCode parentName="p">{`black`}</inlineCode>{`, `}<inlineCode parentName="p">{`invert`}</inlineCode>{`. Передайте в свойство `}<inlineCode parentName="p">{`variant`}</inlineCode>{` нужный`}</p>
    <Playground __position={8} __code={'<div style={{ display: \'flex\', alignItems: \'center\' }}>\n  <Tooltip content={\'Hello there\'} refPropName=\"innerRef\">\n    <Button>Brand variant</Button>\n  </Tooltip>\n  <Space size={Size.large} horizontal />\n  <Tooltip\n    content={\'Hello there\'}\n    variant={Tooltip.Variant.black}\n    refPropName=\"innerRef\"\n  >\n    <Button>Black variant</Button>\n  </Tooltip>\n  <Space size={Size.large} horizontal />\n  <Tooltip\n    content={\'Hello there\'}\n    variant={Tooltip.Variant.invert}\n    refPropName=\"innerRef\"\n  >\n    <Button>Invert variant</Button>\n  </Tooltip>\n</div>'} __scope={{
      props,
      DefaultLayout,
      useState,
      useMemo,
      Button,
      Select,
      Size,
      Space,
      Tooltip,
      ComponentLinks,
      FigmaEmbed,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'flex',
        alignItems: 'center'
      }}>
    <Tooltip content={'Hello there'} refPropName="innerRef" mdxType="Tooltip">
      <Button mdxType="Button">Brand variant</Button>
    </Tooltip>
    <Space size={Size.large} horizontal mdxType="Space" />
    <Tooltip content={'Hello there'} variant={Tooltip.Variant.black} refPropName="innerRef" mdxType="Tooltip">
      <Button mdxType="Button">Black variant</Button>
    </Tooltip>
    <Space size={Size.large} horizontal mdxType="Space" />
    <Tooltip content={'Hello there'} variant={Tooltip.Variant.invert} refPropName="innerRef" mdxType="Tooltip">
      <Button mdxType="Button">Invert variant</Button>
    </Tooltip>
  </div>
    </Playground>
    <h3 {...{
      "id": "позиционирование"
    }}>{`Позиционирование`}</h3>
    <p>{`Tooltip можно позиционировать с помощью пропа `}<inlineCode parentName="p">{`placement`}</inlineCode>{`.`}</p>
    <p>{`По умолчанию используется `}<inlineCode parentName="p">{`auto`}</inlineCode>{`. При таком значении Tooltip позиционируется там, где есть свободное место.`}</p>
    <p>{`Если Tooltip не хватает места для расположения в заданной части, то выбирается положение, в котором Tooltip поместится.
Можно дополнительно управлять положением с помощью пропа `}<inlineCode parentName="p">{`popperOptions`}</inlineCode>{`, в который передаются дополнительные параметры
для Popper.js в соответствии с `}<a parentName="p" {...{
        "href": "https://popper.js.org/docs/v2/modifiers/flip/"
      }}>{`документацией`}</a>{`.`}</p>
    <Playground __position={9} __code={'() => {\n  const placements = useMemo(\n    () => [\n      { label: \'auto\', value: \'auto\' },\n      { label: \'auto-end\', value: \'auto-end\' },\n      { label: \'top-start\', value: \'top-start\' },\n      { label: \'top\', value: \'top\' },\n      { label: \'top-end\', value: \'top-end\' },\n      { label: \'right-start\', value: \'right-start\' },\n      { label: \'right\', value: \'right\' },\n      { label: \'right-end\', value: \'right-end\' },\n      { label: \'bottom-end\', value: \'bottom-end\' },\n      { label: \'bottom\', value: \'bottom\' },\n      { label: \'bottom-start\', value: \'bottom-start\' },\n      { label: \'left-end\', value: \'left-end\' },\n      { label: \'left\', value: \'left\' },\n      { label: \'left-start\', value: \'left-start\' },\n    ],\n    [],\n  )\n  const [placement, setPlacement] = useState(\'auto\')\n  return (\n    <>\n      <Select\n        value={placement}\n        onChange={setPlacement}\n        options={placements}\n        placeholder={\'Select the value\'}\n      />\n      <Space size={Size.small} vertical />\n      <Tooltip\n        content={\'Hello there\'}\n        placement={placement}\n        refPropName=\"innerRef\"\n      >\n        <Button>Hover over me</Button>\n      </Tooltip>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useMemo,
      Button,
      Select,
      Size,
      Space,
      Tooltip,
      ComponentLinks,
      FigmaEmbed,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const placements = useMemo(() => [{
          label: 'auto',
          value: 'auto'
        }, {
          label: 'auto-end',
          value: 'auto-end'
        }, {
          label: 'top-start',
          value: 'top-start'
        }, {
          label: 'top',
          value: 'top'
        }, {
          label: 'top-end',
          value: 'top-end'
        }, {
          label: 'right-start',
          value: 'right-start'
        }, {
          label: 'right',
          value: 'right'
        }, {
          label: 'right-end',
          value: 'right-end'
        }, {
          label: 'bottom-end',
          value: 'bottom-end'
        }, {
          label: 'bottom',
          value: 'bottom'
        }, {
          label: 'bottom-start',
          value: 'bottom-start'
        }, {
          label: 'left-end',
          value: 'left-end'
        }, {
          label: 'left',
          value: 'left'
        }, {
          label: 'left-start',
          value: 'left-start'
        }], []);
        const [placement, setPlacement] = useState('auto');
        return <>
        <Select value={placement} onChange={setPlacement} options={placements} placeholder={'Select the value'} mdxType="Select" />
        <Space size={Size.small} vertical mdxType="Space" />
        <Tooltip content={'Hello there'} placement={placement} refPropName="innerRef" mdxType="Tooltip">
          <Button mdxType="Button">Hover over me</Button>
        </Tooltip>
      </>;
      }}
    </Playground>
    <h3 {...{
      "id": "внешний-контроль-видимости"
    }}>{`Внешний контроль видимости`}</h3>
    <p>{`По умолчанию тултип - неконтролируемый.
Он автоматически появляется при наведении курсора или фокусе на элемент, переданный в `}<inlineCode parentName="p">{`children`}</inlineCode>{`.
Видимость Tooltip можно контролировать извне с помощью пропа `}<inlineCode parentName="p">{`visible`}</inlineCode>{`.
В таком случае автоматическое поведение отключается.`}</p>
    <p>{`Тултип может содержать кнопку закрытия.
Для её отображения передайте проп `}<inlineCode parentName="p">{`closable`}</inlineCode>{`.
При нажатии на кнопку вызывается колбек `}<inlineCode parentName="p">{`onClose`}</inlineCode>{`.`}</p>
    <Playground __position={10} __code={'() => {\n  const [isVisible, setIsVisible] = useState(true)\n  const toggleVisibility = () => setIsVisible(v => !v)\n  return (\n    <Tooltip\n      content={<div>Lorem ipsum dolor sit amet</div>}\n      visible={isVisible}\n      closable={true}\n      onClose={toggleVisibility}\n    >\n      <div style={{ display: \'inline-block\' }}>\n        <Button onClick={toggleVisibility}>Click</Button>\n      </div>\n    </Tooltip>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useMemo,
      Button,
      Select,
      Size,
      Space,
      Tooltip,
      ComponentLinks,
      FigmaEmbed,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [isVisible, setIsVisible] = useState(true);

        const toggleVisibility = () => setIsVisible(v => !v);

        return <Tooltip content={<div>Lorem ipsum dolor sit amet</div>} visible={isVisible} closable={true} onClose={toggleVisibility} mdxType="Tooltip">
        <div style={{
            display: 'inline-block'
          }}>
          <Button onClick={toggleVisibility} mdxType="Button">
            Click
          </Button>
        </div>
      </Tooltip>;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Tooltip} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      